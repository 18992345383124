import { Question } from '@/models/Question';
import { Rpc } from '@/models/Rpc';
import { sanitizeString } from '@/support/String';
import { debounce, cloneDeep } from 'lodash';
import { DefaultAnswer } from '@/models/DefaultAnswer';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Article } from '@/models/Article';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { userRoles } from '@/models/User';
import { Component, Vue } from 'vue-property-decorator';
import { Report, Media } from '@/models/Report';
import LibraryTree from '@/components/LibraryTree/LibraryTree.vue';
import { LibraryItem } from '@/components/LibraryTree/LibraryTree';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';
import { Folder } from '@/models/Folder';
import LibraryDialog from '@/components/dialog/library-dialog/LibraryDialog.vue';
import MediaDialog from '@/components/dialog/media-dialog/MediaDialog.vue';
import { Repair, RepairType } from '@/models/Repair';
import SortableFolderTree from '@/components/sortable-folder-tree/SortableFolderTree.vue';
import { SortItem } from '@/components/sortable-folder-tree/SortableFolderTree';
import LibraryArticle from '@/views/LibraryArticle/LibraryArticle.vue';

@Component<Library>({
  components: {
    LibraryTree,
    QuillEditor,
    LibraryDialog,
    MediaDialog,
    SortableFolderTree,
    LibraryArticle,
  },
})
export default class Library extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  public $pageTitle = 'PeTSBi';

  protected searchInput = '';

  protected selectedReports: Report[] = [];

  protected selectedArticle: LibraryItem | null = null;

  protected selectedArticleClone: LibraryItem | null = null;

  protected folders: LibraryItem[] = [];

  protected draggableFolders: LibraryItem[] | null = [];

  protected active: any = [];

  protected userRoles = userRoles;

  protected selectedImage: Media | null = null;

  protected selectedLibraryItem: LibraryItem | null = null;

  protected defaultAnswers: DefaultAnswer[] = [];

  protected defaultAnswerSearchInput = '';

  protected searchDefaultAnswerDebounce: Function = debounce(this.fetchDefaultAnswers, 300);

  protected repairTypes: RepairType[] = [];

  protected repairTypeSearchInput = '';

  protected searchRepairTypeDebounce: Function = debounce(this.fetchRepairTypes, 300);

  protected selectedQuestions: Question[] = [];

  protected questions: Question[] = [];

  protected questionSearchInput = '';

  protected searchQuestionDebounce: Function = debounce(this.fetchQuestions, 300);

  protected libraryDialogType = '';

  protected libraryItemPayload: LibraryPayload = {
    title: '',
  };

  protected imageModel: Article | null = null;

  protected isLoading = false;

  protected isLoadingArticle = false;

  protected isDisplayingCreateImageDialog = false;

  protected isDisplayingLibraryItemDialog = false;

  protected isEditingLibrarySortOrder = false;

  protected isEditing = false;

  protected quilEditorOptions = {
    modules: {
      toolbar: [
        [{ header: 4 }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link'],
      ],
      resize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    },
    placeholder: 'Type hier...',
  }
  // #endregion

  // #region Lifecycle Hooks / Init

  protected async mounted(): Promise<void> {
    this.emitBreadcrumb();
    this.isLoading = true;
    await this.fetchFolders();
    await this.fetchArticle(this.$route.params.id);
    this.fetchDefaultAnswers();
    this.fetchRepairTypes();
    if (this.isAdmin && this.$store.state.isServiceOrganization) {
      this.fetchQuestions();
    }
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'PeTSBi' }],
    });
  }

  protected onClickSelected(article: LibraryItem): void {
    if (this.isEditing) {
      this.onClickCancelEditArticle();
    }

    this.selectedArticle = article;
    this.fetchArticle(this.selectedArticle.id || '');
  }

  protected onClickFolder(item: LibraryItem): void {
    this.openLibraryItemDialog('folder', item);
  }

  protected onClickArticle(item: LibraryItem): void {
    this.openLibraryItemDialog('article', item);
  }

  protected onClickEdit(item: LibraryItem): void {
    this.openLibraryItemDialog('edit', item);
  }

  protected onClickDelete(item: LibraryItem): void {
    if (item) {
      this.selectedLibraryItem = item;
      this.$store.dispatch('openDialog', this.dialogOptionsDelete);
    }
  }

  protected openLibraryItemDialog(type: string, item: LibraryItem): void {
    this.selectedLibraryItem = item || null;
    this.libraryDialogType = type;
    this.isDisplayingLibraryItemDialog = true;
  }

  protected resetLibraryItem(): void {
    this.libraryDialogType = '';
    this.libraryItemPayload.title = '';
    this.selectedLibraryItem = null;
    this.isDisplayingLibraryItemDialog = false;
  }

  protected onClickDeleteImage(image: Media): void {
    if (image) {
      this.selectedImage = image;
      this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
    }
  }

  protected onClickCreateImageDialog(): void {
    this.imageModel = cloneDeep((this.selectedArticle as Article).mediaEndpoint()) || null;
    this.isDisplayingCreateImageDialog = true;
  }

  protected onClickEditArticle(): void {
    this.selectedArticleClone = cloneDeep(this.selectedArticle);
    this.isEditing = true;
  }

  protected onClickCancelEditArticle(): void {
    (this.selectedArticleClone as Article).media = (this.selectedArticle as Article).media;
    this.selectedArticle = this.selectedArticleClone;
    this.isEditing = false;
  }

  protected async createdLibraryItem(item: LibraryItem) {
    await this.fetchFolders();

    if (item.internal_type === 'folders') {
      return;
    }

    this.selectedArticle = item;
    this.isEditing = true;
  }

  protected onClickEditLibraryOrder() {
    this.draggableFolders = cloneDeep(this.folders);
    this.isEditingLibrarySortOrder = true;
  }

  protected onClickCancelEditLibraryOrder() {
    this.draggableFolders = null;
    this.isEditingLibrarySortOrder = false;
  }

  protected removeSelectedQuestion(index: number) {
    this.selectedQuestions.splice(index, 1);
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  protected async fetchFolders(): Promise<void> {
    const folders = await new Folder()
      .include(['folders', 'articles'])
      .filter({ type: 'library' })
      .sort('sort_order', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (! folders) {
      return;
    }

    folders.forEach((folder: Folder) => {
      folder.createChildren(folder);
    });

    this.folders = folders;
  }

  protected async fetchDefaultAnswers(): Promise<void> {
    const defaultAnswers = await new DefaultAnswer()
      .filter({ title: this.defaultAnswerSearchInput })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.defaultAnswers = defaultAnswers;
  }

  protected async fetchRepairTypes(): Promise<void> {
    const repairTypes = await new Repair()
      .filter({ name: this.repairTypeSearchInput })
      .types()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.repairTypes = repairTypes;
  }

  protected async fetchQuestions(): Promise<void> {
    const questions = await new Question()
      .filter({ search: this.questionSearchInput })
      .include('report_type')
      .limit(100)
      .flatten()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.questions = questions;
  }

  protected async onClickSaveArticle(): Promise<void> {
    if (! this.selectedArticle) {
      return;
    }

    const selectedArticle = (this.selectedArticle as Article);

    const article = await new Article(selectedArticle)
      .update({
        content: selectedArticle.content || '',
        description: selectedArticle.description || '',
        notes: selectedArticle.notes || '',
        default_answers: selectedArticle.default_answers?.map((defaultAnswer: DefaultAnswer) => defaultAnswer.id),
        repair_types: selectedArticle.repair_types?.map((repairType: RepairType) => repairType.uuid),
        questions: this.selectedQuestions.map((question) => question.uuid) || [],
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (article) {
      this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
      this.isEditing = false;
    }
  }

  protected async fetchArticle(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    this.isLoadingArticle = true;

    const selectedArticle = await new Article()
      .include(['media', 'default_answers', 'repair_types'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (selectedArticle) {
      this.selectedArticle = selectedArticle;
      this.defaultAnswers = [...(this.selectedArticle as Article).default_answers || [], ...this.defaultAnswers];
      this.repairTypes = [...(this.selectedArticle as Article).repair_types || [], ...this.repairTypes];
      this.fetchFolders();
      await this.fetchArticleQuestions();
    }

    this.isLoadingArticle = false;
  }

  protected async fetchArticleQuestions() {
    if (! this.selectedArticle) { return; }
    const questions = await new Question()
      .limit(300)
      .filter({ library_articles: [this.selectedArticle.id] })
      .include('report_type')
      .flatten()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (questions) {
      this.selectedQuestions = questions;
    }
  }

  protected async fetchArticleMedia(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    const selectedArticle = await new Article()
      .include(['media'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (selectedArticle) {
      (this.selectedArticle as Article).media = selectedArticle.media;
      this.fetchFolders();
    }
  }

  protected async itemChanged(sortItem: SortItem) {
    const item = sortItem.item.moved ? sortItem.item.moved.element : sortItem?.item?.added?.element || null;
    const data = sortItem.list.map((libraryItem: LibraryItem) => ({
      type: libraryItem.internal_type,
      item: libraryItem.id,
    }));

    if (! sortItem?.parent?.id) {
      this.draggableFolders = cloneDeep(this.folders);
      return;
    }

    if (item) {
      const payload = {
        signature: 'library:folder-attach-children',
        body: {
          parent: sortItem.parent.id,
          data,
        },
      };

      await new Rpc()
        .rpcPost(payload)
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    await this.fetchFolders();
    this.draggableFolders = cloneDeep(this.folders);
  }
  // #endregion

  // #region Getters & Setters

  protected get dialogOptionsReportConfirm(): Options {
    return {
      title: 'Gelukt!',
      text: 'Het opslaan is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
          action: () => {
            this.$emit('reload');
          },
        },
      },
    };
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je ${this.selectedLibraryItem?.title || 'deze item'} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.selectedLibraryItem) {
              return;
            }

            const response = await this.selectedLibraryItem
              .delete()
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
                return null;
              });

            if (response) {
              this.fetchFolders();

              if (this.selectedLibraryItem.id === this.selectedArticle?.id) {
                this.selectedArticle = null;
              }

              this.selectedLibraryItem = null;
              this.isEditing = false;
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedLibraryItem = null;
          },
        },
      },
    };
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je ${this.selectedImage?.file_name || 'deze item'} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.selectedImage || ! this.selectedImage.uuid || ! this.selectedArticle) {
              return;
            }

            await (this.selectedArticle as Article).deleteMediaRequest(this.selectedImage.uuid)
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
                return null;
              });

            this.fetchArticle(this.selectedArticle.id || '');
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedImage = null;
          },
        },
      },
    };
  }

  protected get isAdmin(): boolean {
    return !! this.$store.state.Auth.hasRole(userRoles.AdminRoles);
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

interface LibraryPayload {
  title?: string;
}
