var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("De Bieb")
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm4: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "elementPanel" },
                          [
                            _vm.isAdmin &&
                            _vm.$store.state.isServiceOrganization
                              ? [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "pb-3 ma-0",
                                      attrs: { "justify-space-between": "" }
                                    },
                                    [
                                      !_vm.isEditingLibrarySortOrder
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _vm.folders.length
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "link",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onClickEditLibraryOrder()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " settings "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3823425422
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Volgorde aanpassen")
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isEditingLibrarySortOrder
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "link",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onClickCancelEditLibraryOrder()
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("list")]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1211979303
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Bekijk de lijst")
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            color: "primary",
                                            icon: "create_new_folder",
                                            compact: true,
                                            outline: true
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.openLibraryItemDialog(
                                                "folder"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Nieuwe folder ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _vm.isEditingLibrarySortOrder
                              ? [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c("SortableFolderTree", {
                                        on: { itemChanged: _vm.itemChanged },
                                        model: {
                                          value: _vm.draggableFolders,
                                          callback: function($$v) {
                                            _vm.draggableFolders = $$v
                                          },
                                          expression: "draggableFolders"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _vm.isLoading
                                    ? _c("LoaderCard", {
                                        attrs: {
                                          flat: "",
                                          type: "spinner--center"
                                        }
                                      })
                                    : [
                                        _c("v-text-field", {
                                          staticClass: "search-bar",
                                          attrs: {
                                            label: "Zoeken",
                                            clearable: "",
                                            "clear-icon": "close",
                                            "prepend-icon": "search"
                                          },
                                          model: {
                                            value: _vm.searchInput,
                                            callback: function($$v) {
                                              _vm.searchInput = $$v
                                            },
                                            expression: "searchInput"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "library-tree--fullscreen-scrollable"
                                          },
                                          [
                                            _c("LibraryTree", {
                                              attrs: {
                                                editable:
                                                  _vm.isAdmin &&
                                                  _vm.$store.state
                                                    .isServiceOrganization,
                                                items: _vm.folders,
                                                active: _vm.selectedArticle,
                                                search: _vm.searchInput
                                              },
                                              on: {
                                                onClickSelected:
                                                  _vm.onClickSelected,
                                                onClickFolder:
                                                  _vm.onClickFolder,
                                                onClickArticle:
                                                  _vm.onClickArticle,
                                                onClickEdit: _vm.onClickEdit,
                                                onClickDelete: _vm.onClickDelete
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                ]
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "" } },
                [
                  !_vm.isEditing
                    ? [
                        _c(
                          "div",
                          { staticClass: "elementPanel elementPanel--library" },
                          [
                            _vm.isLoading
                              ? _c("LoaderCard", {
                                  attrs: { flat: "", type: "spinner--center" }
                                })
                              : _vm._e(),
                            _vm.isLoadingArticle
                              ? _c("LoaderCard", {
                                  attrs: { flat: "", type: "spinner--center" }
                                })
                              : _vm._e(),
                            _vm.selectedArticle && !_vm.isLoading
                              ? [
                                  !_vm.isLoadingArticle
                                    ? [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "align-baseline": "",
                                              "justify-end": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _vm.isAdmin &&
                                                          _vm.$store.state
                                                            .isServiceOrganization
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "link",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.onClickEditArticle()
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " edit "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3376855568
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Aanpassen")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("LibraryArticle", {
                                          attrs: {
                                            article: _vm.selectedArticle
                                          }
                                        }),
                                        _vm.selectedQuestions &&
                                        _vm.selectedQuestions.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "library-content"
                                              },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass: "library-title"
                                                  },
                                                  [_vm._v("Vragen:")]
                                                ),
                                                _c(
                                                  "ul",
                                                  [
                                                    _vm._l(
                                                      _vm.selectedQuestions,
                                                      function(
                                                        question,
                                                        index
                                                      ) {
                                                        return [
                                                          _c(
                                                            "li",
                                                            {
                                                              key:
                                                                "repairType-" +
                                                                index
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      question.report_type &&
                                                                      question
                                                                        .report_type
                                                                        .name
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      question
                                                                                        .report_type
                                                                                        .name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " | " +
                                                                                  _vm._s(
                                                                                    question.key
                                                                                  ) +
                                                                                  " | " +
                                                                                  _vm._s(
                                                                                    question.label
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            !_vm.selectedArticle && !_vm.isLoading
                              ? [
                                  _c("span", { staticClass: "fadedText" }, [
                                    _vm._v("Geen artikel geselecteerd")
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : _vm._e(),
                  _vm.isEditing
                    ? [
                        _c(
                          "div",
                          { staticClass: "elementPanel elementPanel--library" },
                          [
                            _vm.selectedArticle
                              ? [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "pb-3",
                                      attrs: {
                                        "justify-space-between": "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            color: "warning",
                                            icon: "delete",
                                            compact: true,
                                            outline: true
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onClickDelete(
                                                _vm.selectedArticle
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Verwijderen ")]
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            shrink: "",
                                            "pa-0": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fadedText link mr-3",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onClickCancelEditArticle()
                                                }
                                              }
                                            },
                                            [_vm._v("Annuleren")]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                color: "primary",
                                                icon: "save",
                                                compact: true,
                                                outline: true
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onClickSaveArticle()
                                                }
                                              }
                                            },
                                            [_vm._v(" Opslaan ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectedArticle.title)
                                          )
                                        ]
                                      ),
                                      _c("QuillEditor", {
                                        attrs: {
                                          options: _vm.quilEditorOptions,
                                          canFullscreen: true
                                        },
                                        model: {
                                          value:
                                            _vm.selectedArticle.description,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedArticle,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedArticle.description"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [_vm._v("Toelichting:")]
                                      ),
                                      _c("QuillEditor", {
                                        attrs: {
                                          options: _vm.quilEditorOptions,
                                          canFullscreen: true
                                        },
                                        model: {
                                          value: _vm.selectedArticle.content,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedArticle,
                                              "content",
                                              $$v
                                            )
                                          },
                                          expression: "selectedArticle.content"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "justify-space-between": "",
                                            "align-center": "",
                                            "pa-0": "",
                                            "ma-0": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "h2",
                                            { staticClass: "library-title" },
                                            [_vm._v("Voorbeelden:")]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                icon: "add",
                                                compact: true,
                                                color: "secondary"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.onClickCreateImageDialog()
                                                }
                                              }
                                            },
                                            [_vm._v(" Voorbeelden toevoegen ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.selectedArticle.media,
                                        function(media, index) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: "images-" + index,
                                                staticClass: "mediaContainer"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mediaContainer__actions"
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href: media.original,
                                                          target: "_blank"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "action"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "remove_red_eye"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "action",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onClickDeleteImage(
                                                              media
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "delete"
                                                          },
                                                          [_vm._v("delete")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c("img", {
                                                  attrs: { src: media.thumb }
                                                })
                                              ]
                                            )
                                          ]
                                        }
                                      )
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [_vm._v("Standaardmotiveringen:")]
                                      ),
                                      _c("v-autocomplete", {
                                        staticClass: "with-border my-3",
                                        attrs: {
                                          items: _vm.defaultAnswers,
                                          "item-text": "title",
                                          label: "Standaardmotiveringen",
                                          multiple: true,
                                          "return-object": "",
                                          "search-input":
                                            _vm.defaultAnswerSearchInput
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.defaultAnswerSearchInput = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.defaultAnswerSearchInput = $event
                                          },
                                          keyup: this
                                            .searchDefaultAnswerDebounce
                                        },
                                        model: {
                                          value:
                                            _vm.selectedArticle.default_answers,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedArticle,
                                              "default_answers",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedArticle.default_answers"
                                        }
                                      }),
                                      _c(
                                        "ul",
                                        [
                                          _vm._l(
                                            _vm.selectedArticle.default_answers,
                                            function(defaultAnswer, index) {
                                              return [
                                                _c(
                                                  "li",
                                                  {
                                                    key:
                                                      "defaultAnswer-" + index
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          defaultAnswer.title
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [_vm._v("Reparatiewijze:")]
                                      ),
                                      _c("v-autocomplete", {
                                        staticClass: "with-border my-3",
                                        attrs: {
                                          items: _vm.repairTypes,
                                          "item-text": "name",
                                          label: "Reparatiewijze",
                                          multiple: true,
                                          "return-object": "",
                                          "search-input":
                                            _vm.repairTypeSearchInput
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.repairTypeSearchInput = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.repairTypeSearchInput = $event
                                          },
                                          keyup: this.searchRepairTypeDebounce
                                        },
                                        model: {
                                          value:
                                            _vm.selectedArticle.repair_types,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedArticle,
                                              "repair_types",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedArticle.repair_types"
                                        }
                                      }),
                                      _c(
                                        "ul",
                                        [
                                          _vm._l(
                                            _vm.selectedArticle.repair_types,
                                            function(repairType, index) {
                                              return [
                                                _c(
                                                  "li",
                                                  {
                                                    key: "repairType-" + index
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          repairType.name
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [_vm._v("Diverse")]
                                      ),
                                      _c("QuillEditor", {
                                        attrs: {
                                          options: _vm.quilEditorOptions,
                                          canFullscreen: true
                                        },
                                        model: {
                                          value: _vm.selectedArticle.notes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectedArticle,
                                              "notes",
                                              $$v
                                            )
                                          },
                                          expression: "selectedArticle.notes"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "library-content" },
                                    [
                                      _c(
                                        "h2",
                                        { staticClass: "library-title" },
                                        [_vm._v("Vragen:")]
                                      ),
                                      _c("v-autocomplete", {
                                        staticClass: "with-border my-3",
                                        attrs: {
                                          items: _vm.questions,
                                          "item-value": "uuid",
                                          label: "Vragen",
                                          multiple: true,
                                          "return-object": "",
                                          "search-input":
                                            _vm.questionSearchInput,
                                          "no-filter": ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.questionSearchInput = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.questionSearchInput = $event
                                          },
                                          keyup: this.searchQuestionDebounce
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  index === 0
                                                    ? [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Geselecteerde vragen "
                                                          )
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text caption"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "(+" +
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedQuestions
                                                                    .length
                                                                ) +
                                                                ")"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    : _vm._e()
                                                ]
                                              }
                                            },
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  item.report_type &&
                                                  item.report_type.name
                                                    ? _c("span", [
                                                        _c("b", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.report_type
                                                                .name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          " | " +
                                                            _vm._s(item.key) +
                                                            " | " +
                                                            _vm._s(item.label) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2790249924
                                        ),
                                        model: {
                                          value: _vm.selectedQuestions,
                                          callback: function($$v) {
                                            _vm.selectedQuestions = $$v
                                          },
                                          expression: "selectedQuestions"
                                        }
                                      }),
                                      _c(
                                        "ul",
                                        [
                                          _vm._l(
                                            _vm.selectedQuestions,
                                            function(question, index) {
                                              return [
                                                _c(
                                                  "li",
                                                  {
                                                    key: "repairType-" + index
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { row: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs11: "" }
                                                          },
                                                          [
                                                            question.report_type &&
                                                            question.report_type
                                                              .name
                                                              ? _c("span", [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        question
                                                                          .report_type
                                                                          .name
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(
                                                                    " | " +
                                                                      _vm._s(
                                                                        question.key
                                                                      ) +
                                                                      " | " +
                                                                      _vm._s(
                                                                        question.label
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs1: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeSelectedQuestion(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [_vm._v("close")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDisplayingLibraryItemDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingLibraryItemDialog,
                callback: function($$v) {
                  _vm.isDisplayingLibraryItemDialog = $$v
                },
                expression: "isDisplayingLibraryItemDialog"
              }
            },
            [
              _c("LibraryDialog", {
                attrs: {
                  selected: _vm.selectedLibraryItem,
                  dialogType: _vm.libraryDialogType
                },
                on: {
                  close: _vm.resetLibraryItem,
                  created: _vm.createdLibraryItem
                },
                model: {
                  value: _vm.isDisplayingLibraryItemDialog,
                  callback: function($$v) {
                    _vm.isDisplayingLibraryItemDialog = $$v
                  },
                  expression: "isDisplayingLibraryItemDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isDisplayingCreateImageDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingCreateImageDialog,
                callback: function($$v) {
                  _vm.isDisplayingCreateImageDialog = $$v
                },
                expression: "isDisplayingCreateImageDialog"
              }
            },
            [
              _c("MediaDialog", {
                attrs: {
                  model: _vm.imageModel,
                  title: "Voorbeelden toevoegen"
                },
                on: {
                  created: function($event) {
                    return _vm.fetchArticleMedia(_vm.selectedArticle.id || "")
                  }
                },
                model: {
                  value: _vm.isDisplayingCreateImageDialog,
                  callback: function($$v) {
                    _vm.isDisplayingCreateImageDialog = $$v
                  },
                  expression: "isDisplayingCreateImageDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }